import PropTypes from 'prop-types';
// @mui
import { Dialog } from '@mui/material';
//
// ----------------------------------------------------------------------

DialogAnimate.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default function DialogAnimate({ open = false, onClose, children, ...other }) {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      {...other}
    >
      {children}
    </Dialog>
  );
}
