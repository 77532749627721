import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  locations: [],
  location: null,
  sortBy: null,
  filters: {
    name: '',
  },

};

const slice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state){
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Locations
    getLocationsSuccess(state, action) {
      state.isLoading = false;
      state.locations = action.payload;
    },

    // GET CATEGORY
    setLocation(state, action) {
      state.isLoading = false;
      state.location = action.payload;
    }
  }

});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getLocations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/locations');
      dispatch(slice.actions.getLocationsSuccess(response.data));
      // return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLocationById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/locations/${id}`);
      dispatch(slice.actions.setLocation(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createLocation(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/locations', {
        location: params
      });
      dispatch(slice.actions.setLocation(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function updateLocation(id, params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/locations/${id}`, {
        location: params
      });
      dispatch(slice.actions.setLocation(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteLocation(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/locations/${id}`);
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}