import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        }
      ],
    },

    // Dashboard Routes
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },

        { 
          path: 'items',
          children: [
            { element: <Navigate to="/items/list" replace />, index: true},
            { path: 'list', element: <ItemList /> },
            { path: 'new', element: <ItemForm /> },
            { path: ':id/edit', element: <ItemForm /> },
            { path: ':id', element: <ItemShow /> },
          ]
        },
        {
          path: 'settings',
          children: [
            { path: '', element: <Settings /> },
            { path: 'general', element: <GeneralSettings /> },
            {
              path: 'categories',
              children: [
                { element: <Navigate to="/settings/categories/list" replace />, index: true },
                { path: 'list', element: <CategoryList /> },
                { path: 'new', element: <CategoryForm /> },
                { path: ':id/edit', element: <CategoryForm /> },
              ]
            },
            {
              path: 'locations',
              children: [
                { element: <Navigate to="/settings/locations/list" replace />, index: true },
                { path: 'list', element: <LocationList /> },
                { path: 'new', element: <LocationForm /> },
                { path: ':id/edit', element: <LocationForm /> }
              ]
            },
            {
              path: 'fields',
              children: [
                { element: <Navigate to="/settings/fields/list" replace />, index: true },
                { path: 'list', element: <FieldList /> },
                { path: 'new', element: <FieldForm /> },
                { path: ':id/edit', element: <FieldForm /> }
              ]
            },
            {
              path: 'flags',
              children: [
                { element: <Navigate to="/settings/flags/list" replace />, index: true },
                { path: 'list', element: <FlagList /> }
              ]
            },
            {
              path: 'users',
              children: [
                { element: <Navigate to="/settings/users/list" replace />, index: true },
                { path: 'list', element: <UserList /> },
                { path: 'new', element: <UserForm /> },
                { path: ':id/edit', element: <UserForm /> }
              ]
            },
          ]
        }
      ],
    },
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));

// Category
const CategoryList = Loadable(lazy(() => import('../pages/settings/category/CategoryList')));
const CategoryForm = Loadable(lazy(() => import('../pages/settings/category/CategoryForm')));

// Items
const ItemList = Loadable(lazy(() => import('../pages/item/ItemList')));
const ItemForm = Loadable(lazy(() => import('../pages/item/ItemForm')));
const ItemShow = Loadable(lazy(() => import('../pages/item/ItemShow')));


// ----------------------------------------------------------------------

// Settings
const Settings = Loadable(lazy(() => import('../pages/settings/Settings')));
const GeneralSettings = Loadable(lazy(() => import('../pages/settings/GeneralSettings')));


// Locations ----------------------------------------------------------------------
const LocationList = Loadable(lazy(() => import('../pages/settings/location/LocationList')));
const LocationForm = Loadable(lazy(() => import('../pages/settings/location/LocationForm')));

// Fields ----------------------------------------------------------------------
const FieldList = Loadable(lazy(() => import('../pages/settings/field/FieldList')));
const FieldForm = Loadable(lazy(() => import('../pages/settings/field/FieldForm')));

// Flags ----------------------------------------------------------------------
const FlagList = Loadable(lazy(() => import('../pages/settings/flag/FlagList')));



// Fields ----------------------------------------------------------------------
const UserList = Loadable(lazy(() => import('../pages/settings/user/UserList')));
const UserForm = Loadable(lazy(() => import('../pages/settings/user/UserForm')));

const NotFound = Loadable(lazy(() => import('../pages/404')));
