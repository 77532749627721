import { createSlice } from "@reduxjs/toolkit";

import axios from '../../utils/axios';

import { dispatch } from "../store";

const initialState = {
  isLoading: false,
  error: null,
  fields: [],
  currentField: {},
  editors: [
    {
      title: "Single line of text",
      description: "e.g. Project number, Dimensions",
      editor: "text",
      kind: "string",
      displayText: "Line"
    },
    {
      title: "Multiple lines of text",
      description: "e.g. Description, Instruction",
      editor: "textarea",
      kind: "string",
      displayText: "Line 1 \n Line 2 \n Line 3"
    },
    {
      title: "Date",
      description: "e.g. Preparation date, Deadline",
      editor: "date",
      kind: "date",
      displayText: "01-01-2021"
    },
    {
      title: "Hyperlink",
      description: "e.g. Tracking link, Manual",
      editor: "url",
      kind: "string",
      displayText: "http://"
    }
  ]
};

const slice = createSlice({
  name: 'field',
  initialState,
  reducers: {

    startLoading(state) {
      state.isLoading = true
    },

    stopLoading(state) {
      state.isLoading = false
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    getFieldsSuccess(state, action) {
      state.isLoading = false;
      state.fields = action.payload;
      state.currentField = {};
    },

    getFieldSucess(state, action) {
      console.log("getFieldSuccess");
      state.isLoading = false;
      state.currentField = action.payload
    }
  }
});

export default slice.reducer;

export const {
  getFieldSucess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getFields() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/fields');
      dispatch(slice.actions.getFieldsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFieldById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/fields/${id}`);
      dispatch(slice.actions.getFieldSucess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function createField(newField) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.post('/fields', {
        field: newField
      })
      dispatch(slice.actions.stopLoading());
      return response;

    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;

    }
  };
}

// ----------------------------------------------------------------------

export function updateField(fieldId, updateField) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/fields/${fieldId}`, {
        field: updateField,
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteField(fieldId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/fields/${fieldId}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
