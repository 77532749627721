// components
import SvgIconStyle from '../../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  list: getIcon('list-solid'),
  tags: getIcon('tags-solid')
};

const sidebarConfig = [
  // GENERAL

  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      { title: 'Items', path: '/items', icon: ICONS.list },
      { title: 'Settings', path: '/settings', icon: ICONS.dashboard },
    ],
  },
];

export default sidebarConfig;
