import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

// slices
import categoryReducer from './slices/category';
import itemReducer from './slices/item';
import locationReducer from './slices/location';
import flagReducer from './slices/flag';
import fieldReducer from './slices/field';
import userReducer from './slices/user';



// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};


const rootReducer = combineReducers({
  category: categoryReducer,
  item: itemReducer,
  location: locationReducer,
  flag: flagReducer,
  field: fieldReducer,
  user: userReducer
});

export { rootPersistConfig, rootReducer };
