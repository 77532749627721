import { createSlice } from "@reduxjs/toolkit";

import axios from '../../utils/axios';

import { dispatch } from "../store";

const initialState = {
  isLoading: false,
  error: null,
  flags: [],
  isOpenModal: false,
  selectedFlag: null
};

const slice = createSlice({
  name: 'flag',
  initialState,
  reducers: {

    startLoading(state) {
      state.isLoading = true
    },

    stopLoading(state) {
      state.isLoading = false
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getFlagsSuccess(state, action) {
      state.isLoading = false;
      state.flags = action.payload
    },

    selectFlag(state, action) {
      state.isOpenModal = true;
      state.selectedFlag = action.payload;
    },

    openModal(state) {
      state.isOpenModal = true;
    },

    closeModal(state) {
      state.isOpenModal = false;
      state.selectedFlag = null;
    }
  }
});

export default slice.reducer;

export const { openModal, closeModal, selectFlag } = slice.actions;


// ----------------------------------------------------------------------

export function getFlags() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/flags');
      dispatch(slice.actions.getFlagsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function createFlag(newFlag) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/flags', {
        flag: newFlag
      });
      dispatch(slice.actions.createFlagSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateFlag(flagId, updateFlag) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/flags/${flagId}`, {
        flag: updateFlag,
      });
      dispatch(slice.actions.updateFlagSuccess(response.data.flag));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteFlag(flagId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/flags/${flagId}`);
      dispatch(slice.actions.deleteFlagSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
