// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login')
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD
};

export const PATHS = {
  dashboard: '/dashboard',
  user: {
    root: '/users',
    list: '/users/list',
    newUser: '/users/list'
  },
  item: {
    root: '/items',
    list: '/items/list',
    new: '/items/new'
  },
  settings: {
    root: '/settings',
    general: '/settings/general',
    category: {
      root: '/settings/categories',
      list: '/settings/categories/list',
      new: '/settings/categories/new'
    },
    location: {
      root: '/settings/locations',
      list: '/settings/locations/list',
      new: '/settings/locations/new'
    },
    field: {
      root: '/settings/fields',
      list: '/settings/fields/list',
      new: '/settings/fields/new'
    },
    flag: {
      root: '/settings/flags',
      list: '/settings/flags/list'
    },
    user: {
      root: '/settings/users',
      list: '/settings/users/list',
      new: '/settings/users/new'
    }
  }
}

export const PATH_DOCS = '/docs';
