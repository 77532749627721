import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  items: [],
  item: null,
  sortBy: null,
  filters: {
    name: '',
  },

};

const slice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state){
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Items
    getItemsSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
      state.item = null;
    },

    // GET CATEGORY
    setItem(state, action) {
      state.isLoading = false;
      state.item = action.payload;
    },

    //  SORT & FILTER Items
    sortByItems(state, action) {
      state.sortBy = action.payload;
    },

    filterItems(state, action) {
      state.filters.name = action.payload.name;
      state.filters.description = action.payload.description;
    }
  }

});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByItems,
  filterItems,
} = slice.actions;

// ----------------------------------------------------------------------

export function getItems() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/items');
      dispatch(slice.actions.getItemsSuccess(response.data));
      // return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getItemById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/items/${id}`);
      dispatch(slice.actions.setItem(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createItem(params) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const success = await axios.post('/items', {
        item: params
      });
      dispatch(slice.actions.setItem(success.data));
      return success;

    } catch(error){
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}


// ----------------------------------------------------------------------

export function updateItem(id, params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/items/${id}`, {
        item: params
      });
      dispatch(slice.actions.setItem(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteItem(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/items/${id}`);
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createItemFlag(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/items/flag`, {
        item_flag: {
          item_ids: params.itemIds,
          flag_id: params.flagId,
          message: params.message
        }
      });
      dispatch(slice.actions.setItem(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}