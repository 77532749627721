import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  categories: [],
  category: null,
  sortBy: null,
  filters: {
    name: '',
  },

};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state){
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Categories
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // GET CATEGORY
    setCategory(state, action) {
      state.isLoading = false;
      state.error = null;
      state.category = action.payload;
    },

    //  SORT & FILTER Categories
    sortByCategories(state, action) {
      state.sortBy = action.payload;
    },

    filterCategories(state, action) {
      state.filters.name = action.payload.name;
      state.filters.description = action.payload.description;
    }
  }

});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByCategories,
  filterCategories,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCategories() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/categories');
      dispatch(slice.actions.getCategoriesSuccess(response.data));
      // return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategoryById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/categories/${id}`);
      dispatch(slice.actions.setCategory(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createCategory(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/categories', {
        category: params
      });
      dispatch(slice.actions.setCategory(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function updateCategory(id, params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/categories/${id}`, {
        category: params
      });
      dispatch(slice.actions.setCategory(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCategory(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/categories/${id}`);
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}