// LAYOUT
// ----------------------------------------------------------------------
import { PATHS } from './routes/paths';

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || 'http://localhost:4000';
export const PATH_AFTER_LOGIN = PATHS.dashboard; // as '/dashboard/app'


export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 65,
  DASHBOARD_DESKTOP_HEIGHT: 70,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 70 - 12,
};

export const NAVBAR = {
  BASE_WIDTH: 220,
  DASHBOARD_WIDTH: 240,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'blue',
  themeLayout: 'horizontal',
  themeStretch: false,
};
